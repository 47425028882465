
<script setup lang="ts">
    import { ref, computed, defineEmits } from "vue";
    import { DataRow } from "o365-data-object";
    import { getOrCreateReactiveProcedure } from 'o365-vue-utils'
    import { OActionList, OActionItem } from "o365-ui-components";
    import { alert } from 'o365-vue-services';
    import { type DataObject } from "o365-dataobject";
    import { isMobile } from "o365.GlobalState.ts";
    import { userSession } from 'o365-modules';
    import MembersModal from "./Signatures.MembersModal.vue";
    import { $t } from "o365-utils";

    export interface Props {
        dataObject: DataObject;
        data: Array<DataRow>;
        row: DataRow;
        idPath?: string;
    };

    const props = defineProps<Props>();

    const isSigning = ref(false);
    const showMembers = ref(false);

    const roleId = computed(() => {
        if (props.row.Person_ID) {
            return null;
        }
        return props.row.Role_ID;
    });

    const isCorrectSequence = computed(() => {
        // always allow unsequenced signatures
        if (props.row.Sequence == null) {
            return true;
        }

        // find unsigned signatures before this one
        const before = props.data.filter(e => e !== props.row && !e.Signed && e.Sequence != null && e.Sequence < props.row.Sequence);
        if (before.length > 0) {
            return false;
        }

        return true;
    });

    // const canSign = computed(() => props.row.CanSign && isCorrectSequence.value);
    const canSign = computed(() => props.row.CanSign);
    const showUnsign = computed(() => props.row.Signed && props.row.SignedBy_ID === userSession.personId);
    const canUnsign = computed(() => showUnsign.value && !props.data.some(e => e !== props.row && e.Signed && e.Signed > props.row.Signed));

    // procedures
    const procSign = getOrCreateReactiveProcedure({
        id: "astp_Scope_ItemsSign" + crypto.randomUUID(),
        procedureName: "astp_Scope_ItemsSign",
    });
    const procSendReminder = getOrCreateReactiveProcedure({
        id: "astp_Scope_ItemsSendReminderSignature" + crypto.randomUUID(),
        procedureName: "astp_Scope_ItemsSendReminderSignature",
    });

    const emits = defineEmits(["signed"])
    // core functions
    async function sign() {
        isSigning.value = true;

        try {
            await procSign.execute({ Item_ID: props.row.Item_ID, Signature_ID: props.row.ID }).then(()=>{
                emits('signed');
            })
        } catch {}

        await props.dataObject.load();

        isSigning.value = false;
    }


    // dropdown functions
    function callMobile() {
        window.location.assign(`tel:${props.row.MobileNo}`);
    }

    function sendEmail() {
        window.location.assign(`mailto:${props.row.Email}`);
    }

    function viewMembers() {
        showMembers.value = !showMembers.value;
    }

    async function sendReminder() {
        await procSendReminder.execute({ Item_ID: props.row.Item_ID, Participant_ID: props.row.ID })
        alert($t("Reminder sent"), "success", { autohide: true, delay: 3000 });
    }

    async function removeSignature() {
        try {
            props.row.Signed = null;
            await props.dataObject.save();
        } catch (err) {
            props.dataObject.cancelChanges();
            await props.dataObject.load();
        }
    }
</script>

<template>
    <div class="h-100 bg-white border d-flex flex-column" :class="{ 'br-0-5': isMobile || true }" style="padding: 0.75rem;">
        <div class="flex-grow-1">
            <template v-if="row.Email || row.Mobileno || roleId || !row.Signed">
                <div class="float-end">
                    <OActionList>
                        <template #target="{ target }">
                            <OButton :target="target" variant="link" color="black" icon="bi-three-dots font-2" :loading="procSendReminder.state.isLoading || row.isSaving" />
                        </template>
                        <template v-if="row.Email">
                            <OActionItem icon="bi-envelope" :text="row.Email" @click="sendEmail()" />
                        </template>
                        <template v-if="row.MobileNo">
                            <OActionItem icon="bi-telephone" :text="row.MobileNo" @click="callMobile()" />
                        </template>
                        <template v-if="roleId">
                            <OActionItem icon="bi-people" :text="$t('View role members')" @click="viewMembers()" />
                        </template>
                        <template v-if="!row.Signed">
                            <OActionItem icon="bi-bell" :text="$t('Send reminder')" @click="sendReminder()" />
                        </template>
                        <template v-if="showUnsign">
                            <template v-if="canUnsign">
                                <OActionItem class="text-danger" icon="bi-pencil-square" :text="$t('Remove my signature')" @click="removeSignature()" />
                            </template>
                            <template v-else>
                                <OActionItem class="text-muted" icon="bi-pencil-square" :text="$t('Cannot remove signature')" disabled />
                            </template>
                        </template>
                    </OActionList>
                </div>
            </template>

            <!-- sequence & respcode -->
            <div class="d-flex gap-1">
                <template v-if="row.Sequence != null">
                    <span class="fw-medium">{{ row.Sequence }}</span>
                </template>
                <template v-if="row.Sequence != null && row.SignTitle != null">
                    <span>-</span>
                </template>
                <template v-if="row.SignTitle != null">
                    <span>{{ row.SignTitle }}</span>
                </template>
            </div>

            <!-- person & role -->
            <div class="">
                <template v-if="row.Person">
                    <div class="d-flex align-items-center gap-1">
                        <i class="bi bi-person-fill" />
                        <div class="fw-medium">{{ row.Person }}</div>
                    </div>
                </template>
                <template v-if="row.Role">
                    <div class="d-flex align-items-center gap-1" :class="{ 'linethrough': row.Person }">
                        <i class="bi bi-people" />
                        <div>{{ row.Role }}</div>
                    </div>
                </template>
            </div>

            <template v-if="row.SignatureCaption">
                <div>{{ row.SignatureCaption }}</div>
            </template>
            <template v-if="row.Represents">
                <div>{{ row.Represents }}</div>
            </template>

            <div class="clear-both" />
        </div>

        <!-- signed by -->
        <template v-if="row.Signed">
            <div class="mt-1 font-n1 text-muted">
                <span>{{ $t("Signed by") + ": " + row.SignedBy }}</span>
                <template v-if="row.SignedBy_ID && row.Person_ID && (row.SignedBy_ID !== row.Person_ID)">
                    <span>{{ `, ${"on behalf of"} ${row.Person}` }}</span>
                </template>
            </div>
        </template>

        <!-- sign button -->
        <div class="mt-1">
            <OButton
                :color="(row.Signed && 'success') || (canSign && 'primary') || ('secondary')"
                :text="row.Signed ? `${$t('Signed')} ${$formatDate(row.Signed)}` : $t('Sign')"
                :loading="isSigning"
                :disabled="!!row.Signed || !row.CanSign"
                @click="sign()"
            />
        </div>
    </div>

    <!-- modal -->
    <template v-if="roleId">
        <MembersModal v-model="showMembers" :roleId="roleId" :idPath="idPath" />
    </template>
</template>

<style scoped>
    .br-0-5 {
        border-radius: 0.5rem;
    }
</style>
